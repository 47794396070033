import { LitElement, html, css } from 'lit';

class BankSelect extends LitElement {
  // Disable shadow DOM and allow global style to take over
  createRenderRoot() {
    return this; // Render directly into the component's element
  }
  
  static properties = {
    selectedBank: { type: String },
    banks: { type: Array }, 
  };

  constructor() {
    super();
    this.selectedBank = ''; // Initial value (no bank selected)
    this.banks = [
      { value: "mbb", label: "Maybank" },
      { value: "cimb", label: "CIMB" },
      { value: "pbb", label: "Public Bank" },
      // { value: "rhb", label: "RHB Bank" },
      // { value: "abmb", label: "Alliance Bank" },
      // { value: "amb", label: "AmBank" },
      // { value: "hsbc", label: "HSBC" },
      // { value: "hlb", label: "Hong Leong Bank" },
      // { value: "affin", label: "Affin Bank" },
      // { value: "bimb", label: "Bank Islam" }
    ];
  }

  render() {
    return html`
      <label for="bank-select-dropdown">Select Bank:</label>
      <select id="bank-select-dropdown" @change=${this._handleBankChange}>
        <option value="" disabled selected>Select a bank</option>
        ${this.banks.map(bank => html`
          <option value=${bank.value} ?selected=${this.selectedBank === bank.value}>${bank.label}</option>
        `)}
      </select>
    `;
  }

  _handleBankChange(event) {
    this.selectedBank = event.target.value;
    this.dispatchEvent(new CustomEvent('bank-selected', { 
      detail: { bank: this.selectedBank } 
    }));
  }
}

customElements.define('bank-select', BankSelect);
